<template>
  <div id="detalle-comportamiento">
    <div class="container">
      <h2 class="text-center d-block">Comportamiento</h2>
      <h5 class="text-center d-block">Solicitud: {{ solicitud.ServiciosOriginacion.id }}</h5>
      <h6 class="text-center d-block">Tipo Solicitud: {{ solicitud.tipo_solicitud }}</h6>

      <div class="row col-md-12 title-pm" id="accordionPM" v-if="tipo_persona == true">
          <div class="card col-md-12">
            <div class="business-heading">
              <h4> Persona Moral </h4>
            </div>
            <div>
              <div class="card-header" id="headingOne">
                <span><small>Referencia:</small></span>
                <h4>{{ solicitud.Scoring.buros_pm[posicionBuroPm].nombre_cliente }}</h4>
              </div>

              <div id="collapseone" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionPM">
                <div class="card-body comportamiento-grl col-md-12 row">

                  <div class='titles-pm col-md-6'>
                    <div class="content-tabla">
                      <div class="generales-data">
                        <span class="heanding-mobile data-green">
                          Tipo de Cartera
                        </span>
                        <span class="col text-right  data-green" v-if="solicitud.Scoring.buros_pm[posicionBuroPm].calificacion_cartera">
                          {{ solicitud.Scoring.buros_pm[posicionBuroPm].calificacion_cartera }}
                        </span>
                        <span class="col text-right  data-green" v-else>
                          Sin información
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Creditos Cerrados
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.creditosCerrados) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Numero de cuentas Abiertas
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.creditosAbiertos) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Otras financieras / simples
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.otrasFinancieraSimples) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Otras Financieras / Arrend. Puro
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.otrasFinancieraArrendadora) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Banco / simple
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.bancoSimple) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Banco / linea de crédito
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.bancoLineaCredito) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Banco / tarjeta empresarial
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.bancoTarjetaEmpresarial) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Saldo vigente abiertas
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice((isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.saldoVigenteAbiertas))) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Saldo Mora 1
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.saldoMoraUno)) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Saldo Mora 2
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.saldoMoraDos)) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Saldo Total
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.saldoTotal)) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Saldo Vencido
                        </span>
                        <span class="col text-right">
                          ${{ formatPrice(isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.saldoVencido)) }}
                        </span>
                      </div>
                    </div>
                    <hr class="d-block d-sm-none" />
                  </div>

                  <div class='titles-pm col-md-6' >
                    <div class="block-cabezal text-center">
                      <h4> Datos Generales </h4>
                    </div>
                    <div class="content-tabla">
                      <!-- <div class="generales-data">
                        <span class="heanding-mobile data-green">
                          Código Score
                        </span>
                        <span class="col text-right  data-green">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].codigo_score) }}
                        </span>
                      </div> -->
                      <!-- <div class="generales-data">
                        <span class="heanding-mobile">
                          FIELD_EM
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].fiel_em) }}
                        </span>
                      </div> -->
                      <!-- <div class="generales-data">
                        <span class="heanding-mobile">
                          productoRequerido
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].producto_requerido) }}
                        </span>
                      </div> -->
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          RFC
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].rfc) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Nombre Del Cliente
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].nombre_cliente) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Direccion1
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].direccion) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Código Postal
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].codigo_postal) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Ciudad
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].ciudad) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Estado
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].estado) }}
                        </span>
                      </div>
                      <!-- <div class="generales-data">
                        <span class="heanding-mobile">
                          Pais Origen Domicilio
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].pais_origen_domicilio) }}
                        </span>
                      </div> -->
                      <!-- <div class="generales-data">
                        <span class="heanding-mobile">
                          Firma Autorización Cliente
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].firma_autorizacion_cliente) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Ambiguedad
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].ambiguedad) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          Incluir Variables CNBV
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].incluir_variables_cnbv) }}
                        </span>
                      </div>
                      <div class="generales-data">
                        <span class="heanding-mobile">
                          indicadorScore
                        </span>
                        <span class="col text-right">
                          {{ isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].indicador_score) }}
                        </span>
                      </div> -->
                    </div>
                    <hr class="d-block d-sm-none" />
                  </div>

                </div>
              </div>
            </div>
            
            <div class="more-information text-center">
              <a class="" data-toggle="collapse" href="#collapseCreditosActivos" role="button" aria-expanded="false" aria-controls="collapseCreditosActivos">
                <small> 
                  Resumen Créditos Activos
                  <img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-up.svg" width="20px" />
                </small>
              </a>
            </div>
            <div class="collapse table-responsive-md" id="collapseCreditosActivos">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Tipo Otorgante</th>
                    <th scope="col">Cuentas Abiertas</th>
                    <th scope="col">Cuentas en MXP</th>
                    <th scope="col">Original</th>
                    <th scope="col">Saldo Actual</th>
                    <th scope="col">Vigente</th>
                    <th scope="col">1-29 dias</th>
                    <th scope="col">30-59 dias</th>
                    <th scope="col">60-89 dias</th>
                    <th scope="col">90-119 dias</th>
                    <th scope="col">120-179 dias</th>
                    <th scope="col">Mas 180 dias</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(credito, index) of solicitud.Scoring.buros_pm[posicionBuroPm].creditosActivos" :key="index">
                    <td>{{credito.tipoUsuario}}</td>
                    <td>{{credito.total}}</td>
                    <td>{{credito.cuentasMxp}}</td>
                    <td>$ {{formatPrice(credito.original)}}</td>
                    <td>$ {{formatPrice(credito.saldoActual)}}</td>
                    <td>$ {{formatPrice(credito.saldoVigente)}}</td>
                    <td>$ {{formatPrice(credito.saldo29)}}</td>
                    <td>$ {{formatPrice(credito.saldo59)}}</td>
                    <td>$ {{formatPrice(credito.saldo89)}}</td>
                    <td>$ {{formatPrice(credito.saldo119)}}</td>
                    <td>$ {{formatPrice(credito.saldo179)}}</td>
                    <td>$ {{formatPrice(credito.saldoMas180)}}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th scope="row">Totales</th>
                    <th>{{isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.creditosAbiertos)}}</th>
                    <th>{{isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.creditosAbiertos)}}</th>
                    <th>${{isEmpty(formatPrice(solicitud.Scoring.buros_pm[posicionBuroPm].totalCreditosActivos))}}</th>
                    <th>${{isEmpty(formatPrice(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.saldoTotal))}}</th>
                    <th>${{formatPrice(saldo_vigente)}}</th>
                    <th>${{formatPrice(saldo_29)}}</th>
                    <th>${{formatPrice(saldo_59)}}</th>
                    <th>${{formatPrice(saldo_89)}}</th>
                    <th>${{formatPrice(saldo_119)}}</th>
                    <th>${{formatPrice(saldo_179)}}</th>
                    <th>${{formatPrice(saldo_180)}}</th>
                  </tr>
                </tbody>
              </table>
            </div>      
            <hr/>
            <div class="more-information text-center">
              <a class="" data-toggle="collapse" href="#collapseCreditosLiquidados" role="button" aria-expanded="false" aria-controls="collapseCreditosLiquidados">
                <small> 
                  Resumen Créditos Liquidados
                  <img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-up.svg" width="20px" />
                </small>
              </a>
            </div>
            <div class="collapse table-responsive-md" id="collapseCreditosLiquidados">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tipo Otorgante</th>
                    <th scope="col">Cuentas cerradas</th>
                    <th scope="col">Cuentas en MXP</th>
                    <th scope="col">Original</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(credito, index) of solicitud.Scoring.buros_pm[posicionBuroPm].creditosLiquidados" :key="index">
                    <th scope="row">{{index}}</th>
                    <td>{{credito.tipoUsuario}}</td>
                    <td>{{credito.total}}</td>
                    <td>{{credito.cuentasMxp}}</td>
                    <td>$ {{formatPrice(credito.original)}}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th colspan="2" scope="row">Totales</th>
                    <th>{{isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.creditosCerrados)}}</th>
                    <th>{{isEmpty(solicitud.Scoring.buros_pm[posicionBuroPm].calculosPM.creditosCerrados)}}</th>
                    <th>$ {{isEmpty(formatPrice(solicitud.Scoring.buros_pm[posicionBuroPm].totalCreditosCerrados))}}</th>
                  </tr>
                </tbody>
              </table>
            </div>      

          </div>
      </div>

      <div class="comportamiento-grl col-md-12 row">
        <div class="col-md-12 title-pm">
          <div class="business-heading">
            <h4> Persona Física</h4>
          </div>
        </div>
        
        <div class='comportamiento col-md-6'>
          <div class="content-tabla">
            <div class="generales-data">
              <span class="heanding-mobile data-green">
                Score de buró
              </span>
              <span class="col text-right data-green">
                {{ solicitud.Scoring.buro[0].score }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas abiertas
              </span>
              <span class="col text-right">
                {{ solicitud.variablesUnykoo.TotalCuentasAbiertas }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 00
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_mop_00"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas atrasos menores
              </span>
              <span class="col text-right">
                {{ solicitud.variablesUnykoo.TotalCuentasAtrasoMenor }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 96
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_mop_96"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 97
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_mop_97"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total créditos máximos pagos fijos
              </span>
              <span class="col text-right">
                ${{ formatPrice(solicitud.Scoring.buro[0]["total_creditos_maximos_pagos_fijos"]) }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total saldos actuales pagos fijos
              </span>
              <span class="col text-right">
                ${{ formatPrice(solicitud.Scoring.buro[0]["total_saldos_actuales_pagos_fijos"]) }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total saldos vencidos pagos fijos
              </span>
              <span class="col text-right">
                $ {{ formatPrice(solicitud.Scoring.buro[0]["total_saldos_vencidos_pagos_fijos"]) }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total créditos máximos revolventes
              </span>
              <span class="col text-right">
                ${{ formatPrice(solicitud.Scoring.buro[0]["total_creditos_maximos_revolventes"]) }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total saldos actuales créditos revolventes
              </span>
              <span class="col text-right">
                ${{ formatPrice(solicitud.Scoring.buro[0]["total_saldos_actuales_revolventes"]) }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total saldos vencidos créditos revolventes
              </span>
              <span class="col text-right">
                $ {{ formatPrice(solicitud.Scoring.buro[0]["total_saldos_vencidos_revolventes"]) }}
              </span>
            </div>
          </div>
          <hr class="d-block d-sm-none" />
        </div>
          
        <div class='comportamiento-minimo col-md-6'>
          <div class="content-tabla-min">
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas cerradas
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_cerradas"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total solicitudes reporte
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["total_solicitudes_reporte"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas negativas actuales
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_negativas_actuales"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas claves historia negativa
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_claves_historia_negativa"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas disputadas
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_disputa"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Existencia declaraciones consumidor
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["existencia_declaraciones_consumidor"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas pagos fijos hipotecas
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_pagos_fijos_hipotecas"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas revolventes abiertas
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["cuentas_revolventes_abiertas"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Fecha de ingreso BD
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]["fecha_ingreso_bd"] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Fecha apertura de cuenta más antigua
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['fecha_apertura_cuenta_mas_antigua']}}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Fecha apertura cuenta más reciente
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['fecha_apertura_cuenta_mas_reciente'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Nueva dirección (reportada en los últimos 60 días)
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['nueva_direccion_reportada_ultimos_60_dias'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Fecha solicitud reporte más reciente
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['fecha_solicitud_reporte_mas_reciente'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Mensaje de alerta
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0].mensaje }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 01
              </span>
              <span class="col text-right">
                {{ solicitud.variablesUnykoo.TotalCuentasMop01 }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 02
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['cuentas_mop_02'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 03
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['cuentas_mop_03'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 04
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['cuentas_mop_04'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 05
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['cuentas_mop_05'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 06
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['cuentas_mop_06'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 07
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['cuentas_mop_07'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP UR
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['cuentas_mop_ur'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Cuentas MOP 99
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['cuentas_mop_99'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Número solicitudes en los últimos 6 meses
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['numero_solicitudes_ultimos_6_meses'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Porcentaje límite de crédito utilizando revolventes
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['pct_limite_credito_utilizado_revolventes'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Número total de cuentas despacho cobranza
              </span>
              <span class="col text-right">
                {{ solicitud.Scoring.buro[0]['numero_total_solicitudes_despachos_cobranza'] }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total pagos fijos
              </span>
              <span class="col text-right">
                ${{ formatPrice(solicitud.Scoring.buro[0]['total_pagos_pagos_fijos']) }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total límites crédito revolventes
              </span>
              <span class="col text-right">
                ${{ formatPrice(solicitud.Scoring.buro[0]['total_limites_credito_revolventes']) }}
              </span>
            </div>
            <div class="generales-data">
              <span class="heanding-mobile">
                Total pagos revolventes
              </span>
              <span class="col text-right">
                ${{ formatPrice(solicitud.Scoring.buro[0]['total_pagos_revolventes']) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="cesion_creditos">
      <VotacionCesionCredito/>
    </div>
    <div v-else>
      <Votacion/>
    </div>
  </div>
</template>

<script>
  import Votacion from '@/apps/comite/pages/Votacion'
  import VotacionCesionCredito from '@/apps/comite/pages/VotacionCesionCreditos'

export default {
  name: 'comportamiento',
  components: {
    Votacion,
    VotacionCesionCredito,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      solicitud: null,
      tipo_persona: false,
      posicionBuroPm: 0,
      saldo_vigente: 0,
      saldo_29: 0,
      saldo_59: 0,
      saldo_89: 0,
      saldo_119: 0,
      saldo_179: 0,
      saldo_180: 0,
      cesion_creditos: false,
    }
  },

  created: function() {
    this.solicitud = this.$store.state.solicitud;    
    this.tipo_persona = this.validarTipoPersona();
    
    if (this.solicitud.Scoring.buros_pm[this.solicitud.Scoring.buros_pm.length - 1])
      this.solicitud.Scoring.buros_pm[this.solicitud.Scoring.buros_pm.length - 1].creditosActivos.forEach(item => {
        this.saldo_vigente += item['saldoVigente']
        this.saldo_29 += item['saldo29']
        this.saldo_59 += item['saldo59']
        this.saldo_89 += item['saldo89']
        this.saldo_119 += item['saldo119']
        this.saldo_179 += item['saldo179']
        this.saldo_180 += item['saldoMas180']
      });
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    if(this.$auth.getUser().roles.comite_ver_creditos_cedidos){
      this.cesion_creditos = true
    }
  },

  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    validarTipoPersona() {
      let tipo_persona = false;

      // this.solicitud.Scoring.buro.forEach(
      //   function(item){
      //     if(item.tipo_persona == 'PM' || item.tipo_persona == 'PFAE'){
      //       tipo_persona = true;
      //     }
      //   }
      // );

      if( this.solicitud.Scoring.buros_pm[0] ) {
        tipo_persona = true;
      }
      // console.log(tipo_persona);
      this.posicionBuroPm = this.solicitud.Scoring.buros_pm.length - 1; //obtener la ultima pocision del buropm
      return tipo_persona;
    },
    isEmpty(value) {
      if (typeof value == undefined) {
        return "";
      }
      return value;
    }
  }
}

</script>

<style lang="scss" scoped>
  #detalle-comportamiento {
    background-color: $white;
    // min-height: 100vh; 
    margin-bottom: 100px;
    
    .comportamiento-grl {
      padding-right: 0;
      
      @include mq-max(mobile-big){
        padding: 0;
        margin: auto;
      }
    }

    .block-cabezal {
      background-color: $color-blue;
      padding: 1rem;
      margin: 1rem 0;
      
      h4 {
        color: $white;
      }
      
      @include mq-min(tablet){
        padding: 2% 0 1% 6%;
      }
    }
    
    .comportamiento {
      padding: 0;
    }
    
    .content-tabla {
      color: $text-primary;
      
      .generales-data {
        display: flex;
        align-items: center;
        
        .data-green {
          color: $color-green !important;
        }
        
        .heanding-mobile {
          font-weight: 400;
          padding-left: 0;
          width: 60%;
        }
        .text-right {
          padding-right: 0;
          font-weight: 700;
        }
      }
    }
    
    .comportamiento-minimo {
      margin: 2rem 0;
      
      .content-tabla-min {
        border: 1px solid $black;
        padding: 1rem;
        font-size: 16px;
        color: $text-primary;
        
        .generales-data {
          display: flex;
          align-items: center;
          
          .heanding-mobile {
            padding-left: 0;
            width: 60%;
          }
          .text-right {
            padding-right: 0;
            font-weight: 700;
          }
        }
      }
      @include mq-min(tablet){
        margin: 0;
      }
    }
    @include mq-min(tablet){
     min-height: 75vh; 
    }
  }
 h2 {
    color: $text-primary;
    padding: 1rem;
  }

  .title-pm{
    margin-bottom: 1rem;
    .business-heading{
      background-color: $color-blue;
      padding: 1rem;
      margin: 1rem 0;
      
      h4 {
        color: $white;
      }
      
      @include mq-min(tablet){
        padding: 2% 0 1% 6%;
      }
    }
    .subtitles-pm{
      background-color: $color-blue;
      padding: 1rem;
      margin: 1rem 0;
      
      h6 {
        color: $white;
      }
      
      @include mq-min(tablet){
        padding: 1% 1% 1% 1%;
      }
    }
  }

  .titles-pm {
    margin: 2rem 0;
    
    .content-tabla-pm {
      padding: 1rem;
      font-size: 16px;
      color: $text-primary;
      
      .generales-data {
        display: flex;
        align-items: center;
        
        .heanding-mobile {
          padding-left: 0;
          width: 60%;
        }
        .text-right {
          padding-right: 0;
          font-weight: 700;
        }
      }
    }
    @include mq-min(tablet){
      margin: 0;
    }
  }

</style>